.inputContainer {
    margin: 0.25vh auto;
    height: 4vh;
    width: 90vw;
    max-width: 600px;
    
    background-color: white;
    border: 1px solid black;
    border-radius: 0.25rem;
    
    display: flex; 
    align-items: center;
}

.button:hover {
    background-color: #C9E5FF;
    cursor: pointer;
}

.inputForm {
    color: gray;
    height: 90%;
    width: 98%;
    margin: auto;
    border: none;
}

.inputForm:focus {
    outline: none;
}

.inputSubmit {
    margin: 2vh auto;
    font-weight: 700;
}