@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap');

.App {
  /* height: 100vh; */
  width: 100vw;
  
  background-color: #84BDF2;
  
  display: flex;
  justify-content: center;
  
  font-family: Raleway;
  font-weight: 500;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.guesses {
    margin-top: 3vh;
}

.option {
    background-color: white;
    border: 1px solid black;
    border-radius: 0.1rem;
    
    padding-left: 2vw;
    margin: 0;
    height: 4vh;
    
    display: flex; 
    align-items: center;
    
    white-space: nowrap;
    overflow: clip;
    text-overflow: clip;
}

.option:hover {
    background-color: #C9E5FF;
    cursor: pointer;
}

.options {
    max-height: 40vh;
    width: 90vw;
    max-width: 600px;

    margin: 1vh auto;
    
    overflow-y: scroll;
}

.rollBos {
    /* margin: 1vh 5vw;
    margin-top: 1vh;
    display: flex;
    flex-direction: row-reverse; */
    position: absolute;
    bottom: 0vh;
    right: 2vw;
    color: white;
}

.unlucky {
    margin: 0.25vh auto;
    width: 90vw;
    max-width: 600px;
    
    background-color: white;
    border: 1px solid black;
    border-radius: 0.25rem;
    
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.share {
    margin: 0.25vh auto;
    height: 4vh;
    width: 90vw;
    max-width: 600px;
    
    background-color: #1EBD04;
    color: white;
    border: 1px solid black;
    border-radius: 0.25rem;
    
    display: flex; 
    align-items: center;
}

.share:hover {
    background-color: #6AD159;
    cursor: pointer;
}

.copied {
    margin: auto;
    padding-top: 0.5vh;
}