.headerContainer {
    height: 8vh;
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
    color: white;
    padding: 1vh 5vw;
    
    border-bottom: 0.3rem solid #C9E5FF;
}

.headerTitle {
    width: 70vw;
}

.mapLink {
    text-decoration: none;
}

.pointer:hover {
    cursor: pointer;
}

.cross {
    width: 16px;
}

.tutorialContainer {
    /* height: 100%; */
    width: 100vw;
    
    position: absolute;
    background-color: #84BDF2;
    
    /* overflow-y: auto; */
}

.invisible {
    display: none;
}