
.tutorial {
    width: 90vw;
    max-width: 600px;
    margin: auto;
}

.horizontalLine {
    height: 0.3rem;
    width: 95vw;
    max-width: 650px;
    margin: auto;
    background-color: #C9E5FF;
}

.credits {
    color: white;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    align-items: flex-end;
}

.creditsText {
    margin: 0;
}

.jumbordle {
    color: white;
}

.startContainer {
    margin: 3vh auto;
    height: 5vh;
    width: 200px;
    max-width: 600px;
    font-size: large;
    
    background-color: white;
    border: 1px solid black;
    border-radius: 0.25rem;
    
    display: flex; 
    align-items: center;
    justify-content: center;
    font-weight: bold;
}